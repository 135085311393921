
import { defineComponent } from 'vue';
import { mapState } from 'vuex'

import TickersTable from '@/components/TickersTable.vue'
import TradeDialog from '@/components/TradeDialog.vue'

export default defineComponent({
  name: 'Home',
  components: { TickersTable, TradeDialog },
  computed: {
    ...mapState('okex', ['usdTickers', 'usdtTickers'])
  },
  methods: {
    getTickers() {
      const tickers = this.type === 0 ? this.usdTickers : this.usdtTickers
      const idx = this.sortByItems[this.sortBy].idx
      return Object.values(tickers)
        .filter((v: any) => !this.filters[this.typeItems[this.type]] || this.filters[this.typeItems[this.type]].has(v[0].coin))
        .sort((a: any, b: any) => idx < 3 ? b[idx].diffRate - a[idx].diffRate : a[idx].instId.charCodeAt(0) - b[idx].instId.charCodeAt(0))
    }
  },
  data() {
    return {
      type: 0,
      typeItems: ['USD', 'USDT'],
      sortBy: 1,
      filters: {
        USD: new Set(['BTC', 'ETH', 'EOS'])
      },
      sortByItems: [
        { idx: 1, text: '当周' },
        { idx: 2, text: '次周' },
        { idx: 3, text: '名字' }
      ]
    }
  }
});
