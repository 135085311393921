<template>
  <el-table
    style="margin-top: 10px;"
    class="ticker-table"
    :data="data"
    :row-class-name="({ rowIndex }) => ({ futures: rowIndex > 0 })"
    @row-click="row => setCurrentInstId(row.instId)"
  >
    <el-table-column
      prop="dd"
      :label="data[0].coin"
    />
    <!-- <el-table-column label="PX">
      <template #default="scope"><i :class="{ red: scope.row.last > data[0].last, green: scope.row.last < data[0].last }">{{scope.row.last}}</i></template>
    </el-table-column> -->
    <el-table-column label="ASK">
      <template #default="scope">
        <div :class="{ red: scope.row.askPx * 1 < data[0].bidPx, green: scope.row.askPx * 1 > data[0].bidPx }">{{scope.row.askPx}}</div>
        <div v-if="positions['long:' + scope.row.instId]">{{positions['long:' + scope.row.instId].availPos}}</div>
      </template>
    </el-table-column>
    <el-table-column label="BID">
      <template #default="scope">
        <div :class="{ red: scope.row.bidPx * 1 > data[0].askPx, green: scope.row.bidPx * 1 < data[0].askPx }">{{scope.row.bidPx}}</div>
        <div v-if="positions['short:' + scope.row.instId]">{{positions['short:' + scope.row.instId].availPos}}</div>
      </template>
    </el-table-column>
    <!-- <el-table-column label="DIFF">
      <template #default="scope"><i :class="{ red: scope.row.diff > 0, green: scope.row.diff < 0 }">{{scope.row.diff && scope.row.diff.toFixed(3)}}</i></template>
    </el-table-column> -->
    <el-table-column label="%">
      <template #default="scope"><i :class="{ red: scope.row.diff > 0, green: scope.row.diff < 0 }">{{scope.row.diffRate && (scope.row.diffRate * 100).toFixed(2) + '%'}}</i></template>
    </el-table-column>
  </el-table>
</template>

<script lang="ts">
import { mapState, mapMutations } from 'vuex'
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    data: Object
  },
  computed: {
    ...mapState('okex', ['positions'])
  },
  methods: {
    ...mapMutations(['setCurrentInstId'])
  }
})
</script>

<style scoped>
.futures .red {
  color: red;
}

.futures .green {
  color: green;
}
</style>