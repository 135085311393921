
import { useStore } from 'vuex'
import { defineComponent, reactive } from 'vue'

export default defineComponent({
  setup() {
    const store = useStore()
    const { apiKey, apiSecret, passphrase } = store.state.okex.api
    return reactive({
      apiKey, apiSecret, passphrase,
      async save() {
        const { apiKey, apiSecret, passphrase } = this
        if (apiKey && apiSecret && passphrase) await store.dispatch('okex/updateApiOpt', { apiKey, apiSecret, passphrase })
      }
    })
  }
})
