
import { mapState, mapMutations } from 'vuex'
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    data: Object
  },
  computed: {
    ...mapState('okex', ['positions'])
  },
  methods: {
    ...mapMutations(['setCurrentInstId'])
  }
})
