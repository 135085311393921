
import store from '@/store'
import { defineComponent, ref } from 'vue'
import { mapState, mapGetters } from 'vuex'

export default defineComponent({
  computed: {
    ...mapGetters('okex', ['positions']),
    ...mapState('okex', ['accounts'])
  },
  setup() {
    const loading = ref(false)

    const updateAccount = async () => {
      loading.value = true
      await store.dispatch('okex/updateAccount').catch(console.error)
      loading.value = false
    }

    return { loading, updateAccount }
  }
})
