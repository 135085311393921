
import { mapState, mapGetters, mapActions, useStore } from 'vuex'
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  computed: {
    ...mapState('okex', ['positisions', 'accounts']),
    ...mapGetters('okex', ['currentTickers', 'currentPositionPairs'])
  },
  methods: {
    ...mapActions('okex', ['batchOpen', 'batchClose']),
    async open(amount) {
      this.opening = true
      try {
        await this.batchOpen({ long: this.currentTickers[0].instId, short: this.currentTickers[1].instId, amount })
      } catch (e) {
        window.alert(e)
      }
      this.opening = false
    },
    async close(short, long, amount) {
      this.closing = true
      try {
        await this.batchClose({ short, long, amount })
      } catch (e) {
        window.alert(e)
      }
      this.closing = false
    }
  },
  setup() {
    const store = useStore()

    const show = ref(!!store.state.currentInstId)
    watch(() => store.state.currentInstId, instId => show.value = !!instId)

    return {
      openAmt: ref(0),
      opening: ref(false),
      closeAmt: ref(0),
      closing: ref(false),
      // handleClose: window.console.log,
      show
    }
  }
})
